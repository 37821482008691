<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HYXW' }">会议新闻</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HY_list' }">重要会议</el-breadcrumb-item>
      <el-breadcrumb-item>会议详情</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="wrap">
      <div class="h1">
        医院承办自治区放射影像专业医疗质量控制中心2024年度工作会议
      </div>
      <section>
        <div class="div_p">
          为进一步提升自治区放射影像专业医疗质量管理与控制水平，规范医疗质量控制中心建设与管理，11月22日，由医院承办的自治区放射影像专业医疗质量控制中心2024年度工作会议在呼和浩特市召开。自治区卫生健康委员会医政医管处一级调研员宝洁、医院副院长赵宝成、自治区放射影像专业医疗质量控制中心主任刘挨师参加了会议。会议由自治区放射影像专业医疗质控中心专家委员会副主任委员高阳主持。
        </div>
        <div class="div_p">
          刘挨师传达了国家放射影像专业质控中心工作会议精神，阐明了质控中心的工作主旨，肯定了各盟市质控中心在2024年的工作成果和贡献，鼓励大家共同把全区放射影像质控工作做好。
        </div>
        <div class="div_p">
          赵宝成在致辞中表示，附属医院已获批21个省级质控中心与15个市级质控中心，今后将承担更多的社会责任，为自治区卫生健康事业作出更大贡献。
        </div>
        <div class="div_p">
          宝洁在致辞中充分肯定了放射影像质控中心2024年的工作成果。同时指出，医疗质量安全是医疗活动的生命线，自治区卫生健康委员会特别重视全区医疗质量控制与检查结果互认工作，将继续大力推进质控中心建设。
        </div>
        <div class="div_p">
          会议特邀中华医学会放射学分会副主任委员张惠茅教授、国家放射影像专业质控中心副主任薛华丹教授围绕“放射科危急值管理和质量控制”、“国家级放射影像专业质控中心2024年工作进展”等主题作专题讲座。张惠茅教授结合先进管理案例对危急值管理理念、管理工具、管理模式等作了详细介绍，为我区放射影像专业质量管理与控制工作提供了新思路、新方法。薛华丹教授以2024年国家级放射影像专业质控中心工作纲领为主线，抽丝剥茧、循序渐进地展示了质控工作要素构成与实施，对我区放射影像质控工作的开展具有重要指导意义。
        </div>
        <div class="div_p">
          会上，全区12个盟市级质控中心负责人分别向大会汇报了本盟市质控工作进展情况。
        </div>
        <div class="div_p">
          此次大会是自治区放射影像专业医疗质控中心成立以来的首次年度盛会。大会内容丰富、精彩纷呈，得到了参会代表的广泛好评和热烈反响。未来，放射影像专业医疗质控中心将继续发挥在区域专业领域的带动引领作用，共同推动我区放射影像质控工作迈向新的台阶，以更加优质、高效的医疗服务守护人民群众健康。
        </div>
        <div class="div_p div_footer">
          通讯员/影像诊断科 赵磊<br />
          责任编辑/杜铃
        </div>
        <div>
          <img src="@/assets/HY20241203/1.png" alt="">
        </div>
        <div>
          <img src="@/assets/HY20241203/2.png" alt="">
        </div>
        <div>
          <img src="@/assets/HY20241203/3.png" alt="">
        </div>
        <div>
          <img src="@/assets/HY20241203/4.png" alt="">
        </div>
        <div>
          <img src="@/assets/HY20241203/5.png" alt="">
        </div>
        <div>
          <img src="@/assets/HY20241203/6.png" alt="">
        </div>
        <div>
          <img src="@/assets/HY20241203/7.png" alt="">
        </div>
        <div>
          <img src="@/assets/HY20241203/8.png" alt="">
        </div>

      </section>
    </div>

  </div>
</template>

<script>
export default {
name:"HY20241203",
}
</script>

<style scoped>
.wrap {
  max-width: 677px;
  margin: 40px auto;
  color: rgb(0, 0, 0);
  background: #fff;
  padding: 10px;
  line-height: 2;
}

img {
  vertical-align: middle;
  max-width: 100%;
  width: 655px !important;
  box-sizing: border-box;
  height: auto !important;
  visibility: visible !important;
  margin: 10px 0;
}

.h1 {
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 14px;
  text-align: center;
}

.div_p {
  text-indent: 2.125em;
  white-space: normal;
  margin: 0px;
  padding: 10px;
  box-sizing: border-box;
  visibility: visible;
}

.div_tit {
  text-align: center;
}

.vertical-img img {
  width: 50% !important;
  display: inline-block;
}
.div_footer {
  text-align: right;
}
</style>